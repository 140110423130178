<template>
  <div class="show">
    <div class="showTop">
      <div class="topleft">
        <img src="../../src/assets/whitelogo.png" alt="" />
        <p>欢迎登录后台管理系统</p>
      </div>
      <div class="topright">
        <p>欢迎您，超级管理员</p>
        <i class="el-icon-switch-button" @click="outdow"></i>
      </div>
    </div>

    <el-row class="main" type="flex">
      <el-col :span="3" style="background: white" class="mainRo">
      <!-- default-active 当前激活菜单的 index -->
        <el-menu
          :default-active="$route.path == '/detail' ? '/equip' : $route.path"
          class="el-menu-vertical-demo"
          background-color="white"
          text-color="#567722"
          active-text-color="white"
          style="padding: 10px 0px"
          router
        >
          <el-menu-item index="/bigshow">
            <i class="el-icon-monitor"></i>
            <span slot="title">大屏展示</span>
          </el-menu-item>
          <el-menu-item index="/user" router>
            <i class="el-icon-office-building"></i>
            <span slot="title">企业管理</span>
          </el-menu-item>
          <el-menu-item index="/equip">
            <i class="el-icon-takeaway-box"></i>
            <span slot="title">设备管理</span>
          </el-menu-item>
          <el-menu-item index="/alarm">
            <i class="el-icon-s-opportunity"></i>
            <span slot="title">报警管理</span>
          </el-menu-item>
          <el-menu-item index="/system">
            <i class="el-icon-menu"></i>
            <span slot="title">系统管理</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="21">
      <!-- 路由占位符 -->
        <router-view></router-view>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { outdown } from "../api/login/index";
export default {
  components: {},
  data() {
    return {
      everthing: "/alarm",
    };
  },
  methods: {
    outdow() {
      outdown().then((res) => {
        console.log(res);
        this.$store.commit("saveToken", res.token);
        console.log(this.$store.state.token);
        this.$router.push("/");
      });
    },
  },
};
</script>


<style lang='less' >
* {
  box-sizing: border-box;
}
.show {
  .showTop {
    width: 100%;
    height: 80px;
    background: rgba(86, 119, 34, 100);
    color: white;
    display: flex;
    justify-content: space-between;
    .topleft {
      width: 700px;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 10px;
      p {
        font-weight: 400;
        margin-left: 100px;
        font-size: 20px;
      }
      img {
        width: 160px;
        height: 50px;
        margin-left: 10px;
      }
    }
    .topright {
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      > i {
        margin-left: 10px;
        cursor: pointer;
        font-size: 24px;
      }
    }
  }
  .main {
    min-height: calc(100vh - 76px);
    background-color: #f2f2f2;
  }
  .el-menu {
    height: 100%;
  }
  .el-menu-item.is-active,
  .el-submenu .el-menu-item.is-active {
    background-color: #567722 !important;
  }
  .el-menu-item {
    font-size: 24px !important;
    height: 70px !important;
    text-align: center;
    font-family: Roboto;
  }
  .el-menu-item [class^="el-icon-"] {
    font-size: 24px;
  }
}
</style>