import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: "",
    password: "",
    sheid: "",
    role: ""
  },
  mutations: {
    saveToken(state, token) {
      state.token = token
      sessionStorage.token = token
    },
    del_token(state) {
      state.token = ''
      sessionStorage.removeItem('token')
    },
    // 修改密码
    savepassword(state, password) {
      state.password = password
    },
    savesheid(state, sheid) {
      state.sheid = sheid
    },

    saverole(state, role) {
      state.role = role
    }
  },
  actions: {},
  modules: {},
  //把所有数据缓存到本地
  plugins: [createPersistedState()],
})