import request from "../../utils/request"

// export function login(paramObj) {
//     return request({
//         url: `api/login/store`,
//         method: 'post',
//         params: paramObj
//     })
// }

// 获取全部资料  及查询
export function login(username = '', password = '') {
    const url = `api/login/store`;
    return request.post(url, {
        username,
        password
    });
}
// tuichu
//退出登录
export function outdown() {
    const url = `/api/logout`;
    return request.get(url);
}