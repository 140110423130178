import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
if (sessionStorage.getItem('token')) {
  store.commit('saveToken', sessionStorage.getItem('token'))
}
const routes = [{
    path: '/home',
    name: 'Home',
    component: Home,
    children: [{
        path: '/alarm',
        name: 'alarm',
        component: () => import('../views/alarm/index.vue')
      },
      {
        path: '/bigshow',
        name: 'bigshow',
        component: () => import('../views/bigshow/index.vue')
      },
      {
        path: '/equip',
        name: 'equip',
        component: () => import('../views/equipment/index.vue')
      },
      {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/equipment/detail.vue')
      },
      {
        path: '/system',
        name: 'system',
        component: () => import('../views/system/index.vue')
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/index.vue')
      }
    ]
  },
  {
    path: '/bigshow1',
    name: 'bigshow1',
    component: () => import('../views/bigshow/index1.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue'),
    children: [{
        path: '/equipuse',
        name: 'equipuse',
        component: () => import('../views/equipment/userindex.vue')
      },
      {
        path: '/alarmuse',
        name: 'alarmuse',
        component: () => import('../views/alarm/userindex.vue')
      },
      {
        path: '/systemuse',
        name: 'systemuse',
        component: () => import('../views/system/userindex.vue')
      },
      {
        path: '/userdetail',
        name: 'userdetail',
        component: () => import('../views/equipment/detailuse.vue')
      },
    ]
  },
  {
    path: '/',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/login/index.vue')
  },

]


// 解决重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    // 用户访问登录页
    next()
  } else {
    // 不是登录页
    if (sessionStorage.getItem('token')) {
      // 用户已经登录
      // if (to.meta && to.meta.role === 0) {
      //   // 是公共页面
      //   NProgress.start()
      //   next()
      // } else {
      //   if (getItem('role')) {
      //     NProgress.start()
      //     next()
      //   } else {
      //     NProgress.start()
      //     next('/login')
      //   }
      // }
      next()
    } else {
      // NProgress.start()
      next('/')
    }
  }
})

export default router